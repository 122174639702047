import Container from "components/globals/Container";
import Footer from "components/globals/Footer";
import Header from "components/globals/Header";
import { Carousel, Slide } from "components/ui/Carousel";
import { publicRoutes } from "routes/routes";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const About: React.FC = () => {
  return (
    <Container>
      <Header title="Maze Softwares - About" />

      <section className="flex flex-col items-center mt-24">
        <h1 className="text-5xl font-extrabold md:text-7xl">
          Hi there,
          <br />
          <span className="text-primary">we're Maze</span> <br />
          <span className="text-primary">softwares</span>
        </h1>

        <p className="text-center mx-5 lg:text-left text-xl lg:text-3xl md:font-light text-opacity-60 mt-10 lg:mt-20 xl:w-[1000px] leading-10">
          We are a team of excellent people with a diverse set of skills and
          expertise. We work together to deliver high-quality work for clients
          and achieve our set goals and objectives.
        </p>
      </section>

      <section className="relative pb-32 mt-20 lg:mt-40">
        <div className="absolute z-0 flex w-full h-full">
          <div className="bg-primary w-1/2 rounded-tr-[60px]"></div>
          <div className="bg-primary w-1/2 rounded-tl-[60px]"></div>
        </div>

        <div className="relative flex flex-col items-center justify-center py-24 text-center text-white md:py-32">
          <h3 className="text-4xl font-bold md:text-5xl">Key Values</h3>
          <p className="text-center mx-5 lg:text-left text-xl lg:text-3xl font-light text-opacity-60 mt-10 lg:mt-20 lg:w-[800px]">
            At Maze softwares, we have fundamental beliefs, principles and
            standards that are deemed important. They shape behaviour, decision
            making and our actions.
          </p>
        </div>
      </section>
      <section className="-mt-40 bg-[#F8F5FF] pb-44 lg:pb-56 overflow-x-hidden">
        <Carousel
          options={{
            autoplay: 5000,
            breakpoints: {
              1100: {
                perView: 2,
              },
              600: {
                perView: 1,
              },
            },
          }}
        >
          <Slide>
            <div className="">
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 border-[#E8E1FB] px-5 py-16 flex flex-col items-start text-left h-[400px]">
                <img
                  src="/icons/security.svg"
                  className="object-contain"
                  alt="security"
                />
                <h4 className="mt-5 mb-3 text-2xl font-semibold">Security</h4>
                <p className="text-lg font-light">
                  We are duly equipped to protect customer data and information
                  from unauthorized access or breaches.
                </p>
              </div>
            </div>
          </Slide>
          <Slide>
            <div className="">
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 border-[#E8E1FB] px-5 py-16 flex flex-col items-start text-left h-[400px]">
                <img
                  src="/icons/innovation.svg"
                  className="object-contain"
                  alt="innovation"
                />
                <h4 className="mt-5 mb-3 text-2xl font-semibold">Innovation</h4>
                <p className="text-lg font-light">
                  We are motivated to continuously improve and create new
                  products and solutions that meet the changing needs of
                  customers.
                </p>
              </div>
            </div>
          </Slide>
          <Slide>
            <div className="">
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 border-[#E8E1FB] px-5 py-16 flex flex-col items-start text-left h-[400px]">
                <img
                  src="/icons/scalability.svg"
                  className="object-contain"
                  alt="scalability"
                />
                <h4 className="mt-5 mb-3 text-2xl font-semibold">
                  Scalability
                </h4>
                <p className="text-lg font-light">
                  We possess the ability to grow and adapt to the increasing
                  demand for their products and services.
                </p>
              </div>
            </div>
          </Slide>
        </Carousel>
      </section>
      <section className="grid grid-rows-4 mx-10 mb-20 -mt-20 md:mb-40 md:grid-rows-1 lg:grid-cols-4 lg:mx-40">
        <div className="bg-white drop-shadow-xl shadow-[#eee] rounded-t-3xl lg:rounded-t-none lg:rounded-l-3xl flex flex-col items-center justify-center py-10 border-2 border-[#E8E1FB]">
          <h4 className="mb-2 font-bold text-7xl text-primary">2019</h4>
          <p className="text-2xl text-black text-opacity-60">founded</p>
        </div>
        <div className="bg-white drop-shadow-xl shadow-[#eee] flex flex-col items-center justify-center py-10 border-2 border-x-0 border-[#E8E1FB]">
          <h4 className="mb-2 font-bold text-7xl text-primary">9x</h4>
          <p className="text-2xl text-black text-opacity-60">faster</p>
        </div>
        <div className="bg-white drop-shadow-xl shadow-[#eee] flex flex-col items-center justify-center py-10 border-2 border-r-0 border-[#E8E1FB]">
          <h4 className="mb-2 font-bold text-7xl text-primary">10+</h4>
          <p className="text-2xl text-black text-opacity-60">
            years of experience
          </p>
        </div>
        <div className="bg-white drop-shadow-xl shadow-[#eee] lg:rounded-b-none rounded-b-3xl lg:rounded-r-3xl flex flex-col items-center justify-center py-10 border-2 border-[#E8E1FB]">
          <h4 className="mb-2 font-bold text-7xl text-primary">20+</h4>
          <p className="text-2xl text-black text-opacity-60">Team members</p>
        </div>
      </section>

      <section className="relative pt-10 pb-40 bg-transparent">
        <div className="flex flex-col mb-20">
          <h2 className="text-4xl font-bold text-black md:text-5xl">
            Benefits of <br className="md:hidden" /> Maze softwares?
          </h2>
          <img
            src="/images/hr-orange.svg"
            className="object-contain self-center pl-[28%] mr-10 h-4 -mt-1"
          />
        </div>

        <div className="flex flex-col justify-between mx-5 md:flex-row md:gap-x-10 md:mx-10 lg:mx-20 gap-y-10 lg:gap-y-0">
          <div className="flex flex-col items-start text-left lg:w-[400px] lg:h-[400px]">
            <img
              src="/images/logo-black.svg"
              className="object-contain w-16 md:w-auto"
            />
            <h4 className="mt-5 mb-3 text-2xl font-semibold">
              Excellent Resources
            </h4>
            <p className="text-lg font-light">
              At Maze Softwares, you have at your disposal a wealth of tools to
              make your process more effective and profitable. As a result,
              clients have access to a wide range of tools so they can stand on
              the winning side.
            </p>
          </div>

          <div className="flex flex-col items-start text-left lg:w-[400px] lg:h-[400px]">
            <img
              src="/images/logo-black.svg"
              className="object-contain w-16 md:w-auto"
            />
            <h4 className="mt-5 mb-3 text-2xl font-semibold">Cost-effective</h4>
            <p className="text-lg font-light">
              We have created an avenue to make every of your funds count. We
              are poised at satisfying our cliental needs, without our clients
              having to break the bank.
            </p>
          </div>

          <div className="flex flex-col items-start text-left lg:w-[400px] lg:h-[400px]">
            <img
              src="/images/logo-black.svg"
              className="object-contain w-16 md:w-auto"
            />
            <h4 className="mt-5 mb-3 text-2xl font-semibold">
              Support and maintenance
            </h4>
            <p className="text-lg font-light">
              We offer support and maintenance for your products to ensure that
              customers can use them effectively. This includes updates and
              upgrades to improve performance and address security
              vulnerabilities.
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center justify-between px-3 mx-5 mt-32 text-white border-2 rounded-3xl bg-primary drop-shadow-lg py-14 lg:py-20 md:px-10 lg:px-10 md:mx-10 xl:mx-40 lg:flex-row lg:mt-20">
          <p className="text-lg font-light leading-8 text-opacity-60 lg:text-left lg:w-4/6">
            Our commitment to providing outstanding service is unwavering, and
            we are <br /> constantly ready to go above and beyond to ensure that
            your experience with us <br /> is a positive one.
          </p>
          <p className="mt-10 lg:w-2/6 lg:mt-0">
            <a
              className="px-16 py-5 text-lg font-semibold text-black bg-white rounded-lg"
              href={publicRoutes.contact}
            >
              Get in touch
            </a>
          </p>
        </div>
      </section>
      <Footer />
    </Container>
  );
};

export default About;
