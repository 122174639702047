import useGetSubdomain from "hooks/useGetSubdomain";
import { Route, Routes as ReactRoutes, useLocation } from "react-router-dom";

import { publicRoutes, subdomainRoutes } from "routes/routes";
import About from "screens/about";
import Contact from "screens/contact";
import Home from "screens/home";
import Privacy from "screens/privacy";
import Services from "screens/services";
import Terms from "screens/terms";

export function RequireAuth({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  // const { vendor } = useGetVendor();

  const location = useLocation();

  // if (!vendor?.id) {
  //   return <Navigate to={publicRoutes.login} state={{ from: location }} />;
  // }

  return children;
}

// export function RequireAuthAdmin({
//   children,
// }: {
//   children: JSX.Element;
// }): JSX.Element {
//   const { admin } = useGetAdmin();

//   const location = useLocation();

//   if (!admin?.id) {
//     return <Navigate to={publicRoutes.register} state={{ from: location }} />;
//   }

//   return children;
// }

const Switch = () => {
  const { subdomain, tldExists } = useGetSubdomain();

  switch (subdomain) {
    case tldExists ? subdomainRoutes.default : undefined:
      return (
        <>
          <Route path={publicRoutes.home} element={<Home />} />
          <Route path={publicRoutes.services} element={<Services />} />
          <Route path={publicRoutes.about} element={<About />} />
          <Route path={publicRoutes.contact} element={<Contact />} />
          <Route path={publicRoutes.terms} element={<Terms />} />
          <Route path={publicRoutes.privacy} element={<Privacy />} />

          {/* <Route path={publicRoutes.helpcenter}>
            <Route path={""} element={<Helpcenter />} />
            <Route path={":help"} element={<Helpcenter />} />
          </Route>
          <Route path={publicRoutes.refer}>
            <Route path={""} element={<Refer />} />
            <Route path={":user"} element={<Refer />} />
          </Route> */}
          <Route path={"*"} element={<Home />} />
        </>
      );

    case subdomainRoutes.admin:
      return (
        <>
          {/* <Route path={adminRoutes.login} element={<AdminLogin />} />
          <Route
            path={adminRoutes.dashboard}
            element={
              <RequireAuthAdmin>
                <AdminDashboard />
              </RequireAuthAdmin>
            }
          /> */}

          {/* <Route path={"*"} element={<AdminLogin />} /> */}
        </>
      );
    default:
      return <Route path={"*"} element={<Home />} />;
  }
};

function Routes() {
  return <ReactRoutes>{Switch()}</ReactRoutes>;
}

export default Routes;
