import Container from "components/globals/Container";
import Footer from "components/globals/Footer";
import Header from "components/globals/Header";

const Privacy: React.FC = () => {
  return (
    <Container>
      <Header title="Maze Softwares - Privacy Policy" />

      <section className="flex flex-col items-center py-14 md:py-20">
        <h1 className="font-extrabold text-5xl md:text-6xl">
          Privacy
          <br />
          <span className="text-primary">policy</span>
        </h1>
      </section>

      <section className="rounded-3xl bg-white drop-shadow-lg border-2 border-[#E8E1FB] mx-5 md:mx-10 xl:mx-40 mb-20 md:mb-40 py-10 md:py-20 px-5 md:px-14 text-left leading-8 md:text-lg font-light">
        <h3 className="font-bold text-lg md:text-xl my-4">INTRODUCTION</h3>
        <p>
          Thank you for choosing Maze Softwares. The following terms and
          conditions apply to anyone that chooses to open an account with Maze
          Softwares. Maze Softwares (Hereinafter referred to as “Company”, “we”,
          “our”, “us” “team”). We enjoin you to sit back and relax as you go
          through these Terms of Service (hereinafter referred to as “Terms”,
          “Terms of Service”) govern your use of our website located at Maze
          Softwares.com. Our Privacy Policy also governs your use of our Service
          and explains how we collect, safeguard and disclose information that
          results from your use of our web pages.
        </p>
        <p>
          Your agreement with us includes these Terms and our Privacy Policy
          (hereinafter referred to as “Agreements”). You acknowledge that you
          have read and understood Agreements, and agree to be bound of them. If
          you do not agree to these terms of finding them not in line with your
          ethics as a person, please you MAY NOT use the service, but please
          make us aware by contacting us.
        </p>
        <h3 className="font-bold text-lg md:text-xl my-4">PRIVACY POLICY</h3>
        <p>
          Maze Softwares. ("we," "our," or "us") is committed to protecting your
          privacy. This Privacy Policy explains how your personal information is
          collected, used, and disclosed by Maze Softwares. This Privacy Policy
          applies to our website, Maze Softwares, and its associated subdomains
          (collectively, our "Service"). By accessing or using our Service, you
          signify that you have read, understood, and agree to our collection,
          storage, use, and disclosure of your personal information as described
          in this Privacy Policy and our Terms of Service.
        </p>
        <h3 className="font-bold text-lg md:text-xl my-4">
          PRECISION AND KEY TERMS
        </h3>

        <ol className="list-inside list-decimal">
          <li>
            Cookie: Cookies are small files that include unique identifiers.
            These cookies can be sent back to the server each time your browser
            requests a new page. It's a way for a website to remember you, your
            preferences, and your habits online.
          </li>
          <li>
            Company: When this policy mentions “Company,” “we,” “us,” or “our,”
            it refers to Maze Softwares that is responsible for your information
            under this Privacy Policy.
          </li>
          <li>
            Country: Where Maze Softwares or the owners/founders of Maze
            Softwares are based, in this case, is Nigeria.
          </li>
          <li>
            Customer: Refers to the company, organization, or person that signs
            up to use the Maze Softwares Service to manage the relationships
            with your consumers or service users.
          </li>
          <li>
            Device: Any internet-connected device such as a phone, tablet,
            computer, or any other device that can be used to visit Maze
            Softwares and use the services.
          </li>
          <li>
            Personnel: Refers to those individuals who are employed by Maze
            Softwares or are under contract to perform a service on behalf of
            one of the parties.
          </li>
          <li>
            Personal Data: Any information that directly, indirectly, or in
            connection with other information — including a personal
            identification number — allows for the identification or
            identifiability of a natural person.
          </li>
        </ol>
        <h3 className="font-bold text-lg md:text-xl my-4">
          INFORMATION PROTECTION
        </h3>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal information when you place an order or enter, submit, or
          access your personal information. We offer the use of a secure server.
          All supplied sensitive/credit/debit information is transmitted via
          secure technology and then encrypted into our Payment gateway
          providers database only to be accessible by those authorized with
          special access rights to such systems, and are required to keep the
          information confidential. After a transaction, your private
          information (credit/debit cards, financials, etc.) is never kept on
          file. We cannot, however, ensure or warrant the absolute security of
          any information you transmit to us or guarantee that your information
          on the Service may not be accessed, disclosed, altered, or destroyed
          by a breach of any of our physical, technical, or managerial
          safeguards.
        </p>
        <h3 className="font-bold text-lg md:text-xl my-4">GOVERNING LAW</h3>
        <p>
          The laws of Nigeria, excluding its conflicts of law rules, shall
          govern this Agreement and your use of our service. Your use of our
          service may also be subject to other local, state, national, or
          international laws.
        </p>

        <h3 className="font-bold text-lg md:text-xl my-4">CLIENT CONSENT</h3>
        <p>
          By using our service, registering an account, and or making an
          investment, you consent to this Privacy Policy.
        </p>

        <h3 className="font-bold text-lg md:text-xl my-4">
          CHANGES TO PRIVACY POLICY
        </h3>
        <p>
          If we decide to change our privacy policy, we will post those changes
          on this page, and/or update the Privacy Policy modification date
          below.
        </p>

        <h3 className="font-bold text-lg md:text-xl my-4">CONTENTS</h3>
        <p>
          Contents found on this website are the sole properties of Maze
          Softwares and you may not sell, download, repost or use in any form
          without express permission from us.
        </p>
        <h3 className="font-bold text-lg md:text-xl my-4">
          THIRD-PARTY SERVICES
        </h3>
        <p>
          We may display, include or make available third-party content
          (including data, information, applications, and other products
          services) or provide links to third-party websites or services
          ("Third-Party Services").
        </p>
        <p>
          You acknowledge and agree that we shall not be responsible for any
          Third-Party Services, including their accuracy, completeness,
          timeliness, validity, copyright compliance, legality, decency,
          quality, or any other aspect thereof. We do not assume and shall not
          have any liability or responsibility to you or any other person or
          entity for any Third-Party Services. Third-Party Services and links
          thereto are provided solely as a convenience to you and you access and
          use them entirely at your own risk and subject to such third parties
          terms and conditions.
        </p>
      </section>

      <Footer />
    </Container>
  );
};

export default Privacy;
