import { publicRoutes } from "routes/routes";

const Footer: React.FC = () => (
  <footer className="bg-primary pt-20 md:pt-32 pb-14 md:pb-20 px-5 md:px-10 xl:px-40">
    <section className="flex flex-col-reverse md:flex-row justify-between items-start xl:items-center gap-y-14 md:gap-y-0">
      <div className="md:mr-20 lg:mr-0">
        <img
          src="/images/logo-white.svg"
          className="w-48 md:w-[200px] xl:w-auto"
          alt="mazesoftwares"
        />
      </div>
      <div className="flex flex-wrap text-white gap-x-32 text-left">
        <div>
          <h4 className="font-light text-xl">Product</h4>
          <ul className="text-xl mt-5">
            <li className="font-semibold mb-3">
              <a href={publicRoutes.services}>Services</a>
            </li>
            <li className="font-semibold">
              <a href="">Projects</a>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="font-light text-xl">Company</h4>
          <ul className="text-xl mt-5">
            <li className="font-semibold mb-3">
              <a href={publicRoutes.about}>About</a>
            </li>
            <li className="font-semibold mb-3">
              <a href="">Blog</a>
            </li>
            <li className="font-semibold mb-3">
              <a href="/#faq">FAQs</a>
            </li>
            <li className="font-semibold">
              <a href={publicRoutes.contact}>Contact</a>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="font-light text-xl">Legal</h4>
          <ul className="text-xl mt-5">
            <li className="font-semibold mb-3">
              <a href={publicRoutes.privacy}>Privacy Policy</a>
            </li>
            <li className="font-semibold">
              <a href={publicRoutes.terms}>Terms and Conditions</a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <p className="text-white mt-10 text-lg font-light text-left">
      Copyright {new Date().getFullYear()} Maze Softwares
    </p>
  </footer>
);

export default Footer;
