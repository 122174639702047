import Container from "components/globals/Container";
import Footer from "components/globals/Footer";
import Header from "components/globals/Header";
import { publicRoutes } from "routes/routes";

const Services: React.FC = () => {
  return (
    <Container>
      <Header title="Maze Softwares - Services" />

      <section className="flex flex-col items-center mt-10 md:mt-24">
        <img
          src="/icons/times.svg"
          className="object-contain self-end md:mr-[10%] hidden md:inline-block"
        />
        <h1 className="font-extrabold text-[43px] md:text-7xl">
          Here's what we can <br />
          <span className="text-primary">do for you</span>
        </h1>

        <p className="text-center mx-5 md:font-light text-opacity-60 mt-10 lg:mt-10 lg:w-[800px]">
          We have a wide range of services that we can provide to our clients in
          <br className="hidden md:block" />
          order to meet their specific needs and requirements. These services
          <br className="hidden md:block" />
          include but are not limited to:
        </p>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 md:gap-x-7 gap-y-5 mt-20 relative">
          <img
            src="/icons/star.svg"
            className="object-contain lg:block absolute top-40 -left-72 hidden"
          />
          <div className="rounded-3xl bg-white drop-shadow-lg flex flex-col items-center justify-end w-[185px] h-[185px] pb-5 border-2 border-[#E8E1FB]">
            <img
              src="/icons/pie-chart.svg"
              className="object-contain w-14 md:w-auto"
            />
            <h3 className="text-xl font-semibold mt-5">
              Product <br /> fundamentals
            </h3>
          </div>

          <div className="rounded-3xl bg-white drop-shadow-lg flex flex-col items-center justify-end w-[185px] h-[185px] pb-5 border-2 border-[#E8E1FB]">
            <img
              src="/icons/design.svg"
              className="object-contain w-14 md:w-auto"
            />
            <h3 className="text-xl font-semibold mt-5">
              Product <br /> design
            </h3>
          </div>
          <div className="rounded-3xl bg-white drop-shadow-lg flex flex-col items-center justify-end w-[185px] h-[185px] pb-5 border-2 border-[#E8E1FB]">
            <img
              src="/icons/source-code.svg"
              className="object-contain w-14 md:w-auto"
            />
            <h3 className="text-xl font-semibold mt-5">
              Software <br /> development
            </h3>
          </div>
          <div className="rounded-3xl bg-white drop-shadow-lg flex flex-col items-center justify-end w-[185px] h-[185px] pb-5 border-2 border-[#E8E1FB]">
            <img
              src="/icons/wrench.svg"
              className="object-contain w-14 md:w-auto"
            />
            <h3 className="text-xl font-semibold mt-5">Maintenance</h3>
          </div>
          <div className="rounded-3xl bg-white drop-shadow-lg flex flex-col items-center justify-end w-[185px] h-[185px] pb-5 border-2 border-[#E8E1FB]">
            <img
              src="/icons/settings.svg"
              className="object-contain w-14 md:w-auto"
            />
            <h3 className="text-xl font-semibold mt-5">
              Product <br /> management
            </h3>
          </div>
          <div className="rounded-3xl bg-white drop-shadow-lg flex flex-col items-center justify-end w-[185px] h-[185px] pb-5 border-2 border-[#E8E1FB]">
            <img
              src="/icons/launch.svg"
              className="object-contain w-14 md:w-auto"
            />
            <h3 className="text-xl font-semibold mt-5">
              Launch & <br /> Growth
            </h3>
          </div>
        </div>
      </section>

      <section className="mt-20 md:mt-40 pb-32 relative">
        <div className="flex w-full h-full absolute z-0">
          <div className="bg-[#F8F5FF] w-1/2 rounded-tr-[60px]"></div>
          <div className="bg-[#F8F5FF] w-1/2 rounded-tl-[60px]"></div>
        </div>

        <div className="pt-20 md:pt-32">
          <div className="rounded-3xl bg-white drop-shadow-lg  border-2 border-[#E8E1FB] mx-3 md:mx-10 lg:mx-40 py-10 lg:py-28 px-3 md:px-7 lg:px-20">
            <div className="flex flex-wrap items-center justify-between">
              <div className="lg:w-1/2">
                <img
                  src="/gif/content-marketing.gif"
                  className="object-contain lg:w-[500px]"
                />
              </div>
              <div className="text-left lg:w-1/2">
                <h3 className="text-2xl md:text-3xl lg:text-4xl text-black text-opacity-90 font-bold">
                  What is Product <br className="hidden lg:block" />
                  fundamentals?
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 lg:pr-20">
                  Product fundamentals encompass the critical components and
                  concepts that are essential for a product to achieve success.
                  They encompass the product's unique selling point, target
                  audience, user profiles, and essential features. Additionally,
                  it includes comprehending the competition, identifying and
                  addressing user issues, and constructing a product plan that
                  outlines the product's development and launch. It's also
                  crucial to have an in-depth understanding of the product
                  development cycle, including design, engineering, marketing
                  and customer service. In summary, product fundamentals provide
                  the basis for building a product, and they guarantee that the
                  product is well-designed, accurately targeted, and meets the
                  needs of its users.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-10 lg:mt-20 gap-x-3 gap-y-5 text-left">
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-1/2">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  Target market and <br /> user needs
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  This includes identifying the target market, understanding
                  user personas and their pain points, and crafting a value
                  proposition that addresses those needs.
                </p>
              </div>
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-1/2 break-all">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  Product development <br /> and planning
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  This includes understanding the competitive landscape,
                  creating a product roadmap, and having a good understanding of
                  the product development process, including design,
                  engineering, marketing, and customer support.
                </p>
              </div>
            </div>

            <p className="text-lg md:text-xl text-left font-light text-opacity-60 leading-8 mt-20">
              We conduct research to gain insights from usability testing,
              quantitative analysis, qualitative analysis and user interviews.
              This helps create a product with core elements such as product's
              value proposition, target market, user personas, and key features.
              We also pay attention to user behavior, understand user needs,
              determine what features need to be added, and help users operate
              the product better. This is the key to building products that
              people are happy to use.
            </p>
          </div>
        </div>

        <div className="pt-20 md:pt-32">
          <div className="rounded-3xl bg-white drop-shadow-lg  border-2 border-[#E8E1FB] mx-3 md:mx-10 lg:mx-40 py-10 lg:py-28 px-3 md:px-7 lg:px-20">
            <div className="flex flex-wrap items-center justify-between">
              <div className="lg:w-1/2">
                <img
                  src="/gif/product-design.gif"
                  className="object-contain lg:w-[500px]"
                />
              </div>
              <div className="text-left lg:w-1/2">
                <h3 className="text-2xl md:text-3xl lg:text-4xl text-black text-opacity-90 font-bold">
                  What is Product <br /> design?
                </h3>
                <p className="text-lg md:text-xl font-light text-opacity-60 mt-5 leading-8 lg:pr-20">
                  This is the method of crafting and developing a product that
                  addresses the needs and desires of its target users. It
                  entails generating and experimenting with concepts, evaluating
                  and improving upon designs, and ultimately resulting in a
                  final product that is both functional, easy to use and
                  visually appealing. The product design process typically
                  involves research, idea generation, sketching, prototyping,
                  testing, and refinement. The objective of product design is to
                  create a product that is attractive, usable, and viable, and
                  that caters to the needs of both the user and the company.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-10 lg:mt-20 gap-x-3 gap-y-5 text-left">
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-1/2 break-all">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  User research
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  This aspect focuses on understanding the needs, goals, and
                  behaviors of the users to inform the design of the interface.
                </p>
              </div>
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-1/2 break-all">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  Interaction design
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  This aspect focuses on how a user interacts with the
                  interface, including the layout, buttons, and controls.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-10 lg:mt-20 gap-x-3 gap-y-5 text-left">
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-1/2 break-all">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  Visual design
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  This aspect focuses on the overall aesthetic of the interface,
                  including color schemes, typography, and imagery.
                </p>
              </div>
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-1/2 break-all">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  Information architecture
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  This aspect focuses on the organization and structure of the
                  content, including navigation and labeling.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-20 md:pt-32">
          <div className="rounded-3xl bg-white drop-shadow-lg  border-2 border-[#E8E1FB] mx-3 md:mx-10 lg:mx-40 py-10 lg:py-28 px-3 md:px-7 lg:px-20">
            <div className="flex flex-wrap items-center justify-between">
              <div className="lg:w-1/2">
                <img
                  src="/gif/software-development.gif"
                  className="object-contain lg:w-[500px]"
                />
              </div>
              <div className="text-left lg:w-1/2">
                <h3 className="text-2xl md:text-3xl lg:text-4xl text-black text-opacity-90 font-bold">
                  Software <br /> development
                </h3>
                <p className="text-lg md:text-xl font-light text-opacity-60 mt-5 leading-8 lg:pr-20">
                  We take a series of steps, including gathering requirements,
                  designing and planning the program, coding and testing
                  software programs, and maintaining and updating it as needed.
                  The goal of software development is to create a program that
                  meets the needs and solves the problems of the users
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-10 lg:mt-20 gap-x-3 gap-y-5 text-left">
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-1/2 break-all">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  Planning and requirements <br className="hidden lg:block" />
                  gathering
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  This aspect involves understanding the needs of the users and
                  determining what the software should do.
                </p>
              </div>
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-1/2 break-all">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  Design
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  This aspect involves creating a plan for how the software will
                  be built and organized.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-10 lg:mt-20 gap-x-3 gap-y-5 text-left">
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-1/2 break-all">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  Coding and Implementation
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  This aspect involves writing the code that makes the software
                  function, using programming languages and frameworks.
                </p>
              </div>
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-1/2 break-all">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  Testing and Maintenance
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  This aspect involves evaluating the software to ensure it
                  works correctly and fixing any issues that are found. Also,
                  maintaining the software over time to adapt to new
                  requirements and fix bugs.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-20 md:pt-32">
          <div className="rounded-3xl bg-white drop-shadow-lg  border-2 border-[#E8E1FB] mx-3 md:mx-10 lg:mx-40 py-10 lg:py-28 px-3 md:px-7 lg:px-20">
            <div className="flex flex-wrap items-center justify-between">
              <div className="lg:w-1/2">
                <img
                  src="/gif/gear.gif"
                  className="object-contain lg:w-[500px]"
                />
              </div>
              <div className="text-left lg:w-1/2">
                <h3 className="text-2xl md:text-3xl lg:text-4xl text-black text-opacity-90 font-bold">
                  Maintenance
                </h3>
                <p className="text-lg md:text-xl font-light text-opacity-60 mt-5 leading-8 lg:pr-20">
                  Software maintenance being the process of updating, modifying
                  and maintaining a software application or system after it has
                  been delivered to the customer. The goal being to ensure that
                  the software continues to function properly and to meet the
                  evolving needs of the users. The activities including bug
                  fixing, adding new features and functionality, improving
                  performance, and adapting the software to changing operating
                  environments. It also includes activities such as configuring,
                  deploying, and testing the software. It is an ongoing process
                  that occurs throughout the software's lifecycle.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-10 lg:mt-20 gap-x-3 gap-y-5 text-left">
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-1/2 break-all">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  Analysis and Design
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  This aspect involves understanding the needs of the users,
                  identifying the problem that needs to be solved and planning
                  the solution, including the design of the software, the
                  development of a prototype, and the definition of the
                  requirements.
                </p>
              </div>
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-1/2 break-all">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  Implementation and <br /> Testing
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  This aspect involves the actual construction of the software,
                  including coding, testing, debugging, and maintaining the
                  software over time. This includes writing the code, testing
                  and debugging it, and verifying that it meets the requirements
                  and specifications.
                </p>
              </div>
            </div>

            <p className="text-lg md:text-xl text-left font-light text-opacity-60 leading-8 mt-20">
              We ensure that the product will continue to function flawlessly
              and efficiently after approval. This includes tasks such as
              troubleshooting and fixing errors, updating and updating software,
              and providing technical support to users. Maze Softwares is also
              responsible for maintaining documentation and other forms of user
              support and working with other members of the development team to
              improve the overall quality of the product.
            </p>
          </div>
        </div>

        <div className="pt-20 md:pt-32">
          <div className="rounded-3xl bg-white drop-shadow-lg  border-2 border-[#E8E1FB] mx-3 md:mx-10 lg:mx-40 py-10 lg:py-28 px-3 md:px-7 lg:px-20">
            <div className="flex flex-wrap items-center justify-between">
              <div className="lg:w-1/2">
                <img src="/gif/idea.gif" className="object-contain w-[400px]" />
              </div>
              <div className="text-left lg:w-1/2">
                <h3 className="text-2xl mt-2 md:text-4xl lg:text-5xl text-black text-opacity-90 font-bold">
                  What is Product <br /> management?
                </h3>
                <p className="text-lg md:text-xl font-light text-opacity-60 mt-5 leading-8 lg:pr-20">
                  We conduct research to gain insights from usability testing,
                  quantitative analysis, qualitative analysis and user
                  interviews. This helps create a product with core elements
                  such as product's value proposition, target market, user
                  personas, and key features. We also pay attention to user
                  behavior, understand user needs, determine what features need
                  to be added, and help users operate the product better. This
                  is the key to building products that people are happy to use.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-10 lg:mt-20 gap-x-3 gap-y-5 text-left">
              <div className="rounded-3xl bg-white drop-shadow-lg border-2 py-8 md:py-14 px-5 md:px-10 lg:w-full break-all">
                <h3 className="text-2xl md:text-3xl lg:text-2xl text-black text-opacity-90 font-bold">
                  Product value proposition
                </h3>
                <p className="text-base md:text-lg font-light text-opacity-60 mt-5 leading-8 ">
                  We conduct research to gain insights from usability testing,
                  quantitative analysis, qualitative analysis and user
                  interviews. This helps create a product with core elements
                  such as product's value proposition, target market, user
                  personas, and key features.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-20 md:pt-32">
          <div className="rounded-3xl bg-white drop-shadow-lg  border-2 border-[#E8E1FB] mx-3 md:mx-10 lg:mx-40 py-10 lg:py-28 px-3 md:px-7 lg:px-20">
            <div className="flex flex-wrap items-center justify-between">
              <div className="lg:w-1/2">
                <img
                  src="/gif/graph.gif"
                  className="object-contain w-[400px]"
                />
              </div>
              <div className="text-left lg:w-1/2">
                <h3 className="text-2xl md:text-3xl lg:text-4xl text-black text-opacity-90 font-bold">
                  Launch and <br /> Growth
                </h3>
                <p className="text-lg md:text-xl font-light text-opacity-60 mt-5 leading-8 lg:pr-20">
                  This is the process of introducing a new product or service to
                  the market and then growing its customer base and revenue. The
                  product launch process typically includes market research,
                  product development, testing, and marketing campaigns to
                  generate interest and demand for the product. After the
                  launch, the focus shifts to growth, which includes activities
                  such as customer acquisition, retention, and expansion, as
                  well as ongoing product development and improvement to meet
                  the changing needs of customers. The goal of product launch
                  and growth is to establish a successful, sustainable business
                  around the product or service.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-3xl bg-primary text-white drop-shadow-lg border-2 py-14 lg:py-20 px-3 md:px-10 lg:px-10 mx-5 md:mx-10 xl:mx-40 flex flex-col lg:flex-row justify-between items-center mt-32">
          <p className="text-lg font-light text-opacity-60 lg:text-left lg:w-4/6 leading-8">
            Our commitment to providing outstanding service is unwavering, and
            we are <br /> constantly ready to go above and beyond to ensure that
            your experience with us <br /> is a positive one.
          </p>
          <p className="lg:w-2/6 mt-10 lg:mt-0">
            <a
              className="text-black font-semibold bg-white text-lg rounded-lg px-16 py-5"
              href={publicRoutes.contact}
            >
              Get in touch
            </a>
          </p>
        </div>
      </section>
      <Footer />
    </Container>
  );
};

export default Services;
