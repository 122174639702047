export const publicRoutes = {
  home: "/",
  services: "/services",
  about: "/about",
  contact: "/get-started",
  terms: "/terms-and-conditions",
  privacy: "/privacy-policy",
};

export const adminRoutes = {
  dashboard: "/",
  logout: "/logout",
  login: "/login",
  register: "/register",
};

export const subdomainRoutes = {
  default: "",
  admin: "admin",
};
