import emailjs from "@emailjs/browser";
import Container from "components/globals/Container";
import Footer from "components/globals/Footer";
import Header from "components/globals/Header";
import { useRef } from "react";
import { toast } from "react-toastify";

const Contact: React.FC = () => {
  const form = useRef<any>();

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID || "",
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID || "",
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY || ""
      )
      .then(
        (result) => {
          toast("Message sent successfully", { type: "success" });
        },
        (error) => {
          console.log(error.text);
          toast("Something went wrong, please try again later", {
            type: "success",
          });
        }
      );
  };
  return (
    <Container>
      <Header title="Maze Softwares - Get Started" />

      <section className="flex flex-col  items-center py-20 md:pt-32 md:py-40 bg-[#4D24C3]">
        <div className="w-full text-white">
          <h3 className="text-6xl font-bold">
            <span className="text-[#f5d3e8]">Ready to</span> <br />
            <span>get started?</span>
          </h3>
          <div className="mt-5 font-light leading-8">
            <p>Tell us about your project and we will:</p>
            <ul className="list-disc list-inside">
              <li>Reply within 3 business hours.</li>
              <li>
                Discuss your project on a quick call (We will sign an NDA if
                requested)
              </li>
              <li>You get a quote</li>
            </ul>
          </div>

          <form
            method="post"
            ref={form}
            onSubmit={sendEmail}
            className="mx-5 mt-20 md:mx-20 xl:mx-40"
          >
            <div className="grid md:grid-cols-2 gap-x-5 gap-y-5">
              <input
                required
                name="firstName"
                type="text"
                placeholder="First Name"
                className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
              />
              <input
                required
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
              />
              <input
                required
                name="phoneNumber"
                type="text"
                placeholder="Phone Number"
                className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
              />
              <input
                required
                name="email"
                type="text"
                placeholder="Email Address"
                className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
              />
              <input
                required
                name="company"
                type="text"
                placeholder="Company"
                className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
              />
              <input
                required
                name="Industry"
                type="text"
                placeholder="Industry"
                className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
              />
              <input
                required
                type="text"
                name="budget"
                placeholder="Budget Size"
                className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
              />
              <input
                name="hearFrom"
                type="text"
                placeholder="Where did you here about us? (optional)"
                className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
              />
              <textarea
                required
                name="aboutProject"
                placeholder="Tell us about your project"
                className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
                rows={6}
              />
              <textarea
                name="anythingElse"
                placeholder="Anything else?"
                className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
                rows={6}
              />
            </div>

            <div className="flex items-center justify-center mb-8 mt-14">
              <button className="px-16 py-4 text-lg font-semibold text-black bg-white rounded-lg">
                Submit
              </button>
            </div>
          </form>
          <p className="text-white">
            or contact us via email-{" "}
            <a href="mailto:support@mazesoftwares.com">
              support@mazesoftwares.com
            </a>
          </p>
        </div>
      </section>
      <Footer />
    </Container>
  );
};

export default Contact;
