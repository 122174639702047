import Container from "components/globals/Container";
import Footer from "components/globals/Footer";
import Header from "components/globals/Header";

const Terms: React.FC = () => {
  return (
    <Container>
      <Header title="Maze Softwares - Terms and Conditions" />

      <section className="flex flex-col items-center py-14 md:py-20">
        <h1 className="font-extrabold text-5xl md:text-6xl">
          Terms and
          <br />
          <span className="text-primary">Conditions</span>
        </h1>
      </section>

      <section className="rounded-3xl bg-white drop-shadow-lg border-2 border-[#E8E1FB] mx-5 md:mx-10 xl:mx-40 mb-20 md:mb-40 py-10 md:py-20 px-5 md:px-14 text-left leading-8 md:text-lg font-light">
        <h3 className="font-bold text-lg md:text-xl my-4">INTRODUCTION</h3>
        <p>
          Thank you for choosing Maze Softwares. The following terms and
          conditions apply to anyone that chooses to open an account with Maze
          Softwares. Maze Softwares (Hereinafter referred to as “Company”, “we”,
          “our”, “us” “team”). We enjoin you to sit back and relax as you go
          through these Terms of Service (hereinafter referred to as “Terms”,
          “Terms of Service”) govern your use of our website located at Maze
          Softwares.com. Our Privacy Policy also governs your use of our Service
          and explains how we collect, safeguard and disclose information that
          results from your use of our web pages.
        </p>
        <p>
          Your agreement with us includes these Terms and our Privacy Policy
          (hereinafter referred to as “Agreements”). You acknowledge that you
          have read and understood Agreements, and agree to be bound of them. If
          you do not agree to these terms of finding them not in line with your
          ethics as a person, please you MAY NOT use the service, but please
          make us aware by contacting us.
        </p>
        <h3 className="font-bold text-lg md:text-xl my-4">GENERAL TERMS</h3>
        <p>
          By using Maze Softwares, you agree to be bound by the terms laid out
          in the terms and conditions of Maze Softwares. These terms apply to
          the entire website. The team shall not be liable for any direct,
          indirect, special, or consequent damage. If your use of materials from
          this site results in the need for servicing, repair, or correction of
          equipment or data, you assume any costs thereof. We will not be
          responsible for any outcome that may occur during the usage of our
          resources. We reserve the right to make changes and revise the
          resources usage policy at any moment.
        </p>
        <h3 className="font-bold text-lg md:text-xl my-4">
          CUSTOMER CONCURRENCE
        </h3>
        <p>
          You agree and concur with the privacy policy when you use our services
          regardless of how or when you use them.
        </p>
        <h3 className="font-bold text-lg md:text-xl my-4">REGULATOR</h3>
        <p>
          Maze Softwares is the regulator and solely in charge of the privacy of
          your data on the Maze Softwares website. <br /> If you have any
          inquiry about this privacy policy or our privacy doctrines, please
          contact us.
        </p>
        <h3 className="font-bold text-lg md:text-xl my-4">
          Content, Copyrights and Trademarks
        </h3>
        <p>
          All text, material, data, and information, data files, description of
          our services or/and our products, graphics, images, user interfaces,
          visual interfaces, photographs, trademarks, logos and computer code
          etc. ("Content"), contained on the Site is owned, controlled or
          licensed by or to Company, and is protected by applicable intellectual
          property and other laws, including trademark and copyright laws.
          Company owns and retains all copyrights in the Content.
        </p>
        <p>
          You acknowledge that Company does not make any representations or
          warranties about the Content which you may have access to. Under no
          circumstances is Company liable in any way for any Content, including,
          but not limited to: any infringing Content, any errors or omissions in
          Content, or for any loss or damage of any kind incurred as a result of
          the use of any Content posted, transmitted, linked from, or otherwise
          accessible through or made available via the Site. You agree that you
          are solely responsible for your reuse of Content made available
          through the Site, including providing proper attribution. You should
          review the terms of the applicable license before you use the Content
          so that you know what you can and cannot do.{" "}
        </p>
        <p>
          Except as expressly provided in these Terms, no part of the Site and
          no Content may be copied, reproduced, modified, published,
          republished, uploaded, posted, publicly displayed, encoded,
          translated, transmitted or distributed in any way (including
          "mirroring") to any other computer, server, Web site or other medium
          for publication or distribution or for any commercial enterprise,
          without Company’s express prior written consent. You also agree not to
          modify, rent, lease, loan, sell, distribute, transmit, broadcast, or
          create derivative works based on the Content or the Site, in whole or
          in part, by any means.
        </p>
        <p>
          Company’s logos and trademarks are trademarks and the property of
          Company. The appearance, layout, colour scheme, and design of the Site
          are protected trade dress. You do not receive any right or license to
          use the foregoing. We may use and incorporate into the Site any
          suggestions or other feedback you provide, without payment or
          condition.
        </p>
        <p>
          You may use the Site or/and Content solely for your non-commercial,
          personal purposes and/or to learn about Company’s products and
          services, and solely in compliance with these Terms; provided that you
          not remove any proprietary notice language in Content or part of
          Content, do not copy or post such Content or part of Content on any
          networked computer or broadcast it in any media, make no modifications
          to any such Content or part of Content and not make any additional
          representations or warranties relating to Site, such Content or part
          of Content or/and Company’s products or/and services.
        </p>
        <h3 className="font-bold text-lg md:text-xl my-4">KEY TERMS</h3>
        <p>For this Terms & Conditions:</p>
        <ol className="list-inside list-decimal">
          <li>
            Cookie: A small amount of data generated by a website and saved by
            your web browser. It is used to identify your browser, provide
            analytics, and remember information about you such as your language
            preference or login information.
          </li>
          <li>
            Company: When this policy mentions “Company,” “we,” “us,” “team,” or
            “our,” it refers to (company name) that is responsible for your
            information under this Privacy Policy.
          </li>
          <li>
            Country: Where Maze Softwares is located, in this case, is Nigeria.
          </li>
          <li>
            Customer: Refers to the Client, organization, or person that signs
            up to use the Maze Softwares Service to manage the relationships
            with your consumers or service users.
          </li>
        </ol>
        <h3 className="font-bold text-lg md:text-xl my-4">RESTRICTIONS</h3>
        <p>You agree not to use the service</p>
        <ol className="list-inside list-decimal">
          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material, including any “junk mail”, “chain letter,”
            “spam,” or any other similar solicitation.
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone’s
            use or enjoyment of Service, or which, as determined by us, may harm
            or offend Company or users of Service or expose them to liability.
          </li>
          <li>
            To impersonate or attempt to impersonate Company, a Company
            employee, another user, or any other person or entity.
          </li>
        </ol>
        <p>You also agree not to</p>
        <ol className="list-inside list-decimal">
          <li>
            Use any device, software, or routine that interferes with the proper
            working of Service.
          </li>
          <li>
            Introduce any viruses, Trojan horses, worms, logic bombs, or other
            material which is malicious or technologically harmful.
          </li>
          <li>
            Take any action that may damage or falsify the Company rating.
          </li>
        </ol>
        <h3 className="font-bold text-lg md:text-xl my-4">CONTENTS</h3>
        <p>
          Contents found on this website are the sole properties of Maze
          Softwares and you may not sell, download, repost or use in any form
          without express permission from us.
        </p>
        <h3 className="font-bold text-lg md:text-xl my-4">
          CHANGES OF OUR TERMS AND CONDITIONS
        </h3>
        <p>
          You acknowledge and agree that we may stop (permanently or
          temporarily) providing the Service (or any features within the
          Service) to you or users generally at our sole discretion, without
          prior notice to you. You may stop using the Service at any time. You
          do not need to specifically inform us when you stop using the Service.
          You acknowledge and agree that if we disable access to your account,
          you may be prevented from accessing the Service, your account details,
          or any files or other materials which are contained in your account.
          If we decide to change our Terms & Conditions, we will post those
          changes on this page, and/or update the Terms & Conditions
          modification date below.
        </p>
      </section>

      <Footer />
    </Container>
  );
};

export default Terms;
