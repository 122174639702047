import emailjs from "@emailjs/browser";
import Container from "components/globals/Container";
import Footer from "components/globals/Footer";
import Header from "components/globals/Header";
import { Carousel, Slide } from "components/ui/Carousel";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { publicRoutes } from "routes/routes";

interface FAQ {
  title: string;
  content: string;
}
const faqs: FAQ[] = [
  {
    title: "How do I get started with Maze Softwares?",
    content: `To get started with Maze softwares, all you have to do is click "Get started" and fill the required details. We then get in contact with you to define the project requirements and establish a clear project plan. This will typically involve regular meetings and communication to ensure that the project stays on track and that any issues or concerns are addressed in a timely manner.`,
  },
  {
    title: "How long will it take to create my website/app?",
    content: "",
  },
  {
    title: "What results can I expect from using your services?",
    content: "",
  },
  {
    title: "What if I want to make changes to the design?",
    content: "",
  },
  {
    title: "My site launched but something broke. Who should fix it?",
    content: "",
  },
];

const Home: React.FC = () => {
  const [seletedFaq, setSelectedFaq] = useState<FAQ | null>(null);

  const toggleFaq = (faq: FAQ) => {
    setSelectedFaq(seletedFaq?.title === faq.title ? null : faq);
  };

  const form = useRef<any>();

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID || "",
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID || "",
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY || ""
      )
      .then(
        (result) => {
          toast("Message sent successfully", { type: "success" });
        },
        (error) => {
          console.log(error.text);
          toast("Something went wrong, please try again later", {
            type: "success",
          });
        }
      );
  };
  return (
    <Container>
      <Header title="Maze Softwares" />
      <section className="flex flex-col mt-16 text-center md:items-start md:pl-28 md:mt-24">
        <h1 className="px-2 text-4xl font-extrabold md:text-5xl lg:text-7xl">
          We build <span className="text-primary">market-leading</span>&nbsp;
          <br className="hidden md:block" />
          softwares for businesses
        </h1>
        <img
          src="/images/hr.svg"
          className="object-contain self-center w-[200px] md:w-[250px] lg:w-auto md:ml-[5%] lg:-mr-[30%] xl:mr-[2%] 2xl:mr-[18%] h-4 -mt-1"
        />
        <div className="relative flex flex-col items-start justify-start w-full md:flex-row">
          <div className="mt-6">
            <p className="px-4 font-semibold text-left text-black text-opacity-60">
              Outsource with us and get speed, aesthetics and high
              performance&nbsp;
              <br className="hidden lg:block" />
              for your software development.
            </p>
            <div className="flex flex-col items-center mt-6 lg:flex-row gap-x-8 gap-y-5">
              <a
                className="px-20 py-4 text-white rounded-lg bg-primary md:px-14"
                href={publicRoutes.contact}
              >
                Get in touch
              </a>
              <a
                className="text-black border border-black rounded-lg px-24 md:px-16 py-3.5"
                href={publicRoutes.services}
              >
                Services
              </a>
            </div>
          </div>
          <div className="mt-5 lg:absolute md:mt-0 lg:right-52">
            <img src="/images/landing.png" className="w-[550px]" />
          </div>
        </div>
      </section>

      <section className="mx-5 lg:mx-60 mt-10 lg:mt-[360px] py-5 md:py-10 px-5 mb-20 bg-white border border-black border-opacity-20 rounded-lg flex flex-col md:flex-row items-center justify-center gap-x-10">
        <p className="lg:w-2/6">Trusted by next gen companies</p>

        <div className="flex items-center justify-between w-full mt-4 md:justify-evenly">
          <img
            src="/images/incorpuk.png"
            className="object-contain w-[105px] md:w-44"
          />
          <img
            src="/images/pavelify.png"
            className="object-contain w-[105px] md:w-44"
          />
          <img
            src="/images/bitoshi.png"
            className="object-contain w-[105px] md:w-44"
          />
        </div>
      </section>

      <section className="relative pb-32 mt-20 lg:mt-40">
        <div className="absolute z-0 flex w-full h-full">
          <div className="bg-[#F8F5FF] w-1/2 rounded-tr-[60px]"></div>
          <div className="bg-[#F8F5FF] w-1/2 rounded-tl-[60px]"></div>
        </div>
        <div className="relative z-50 pt-10 lg:pt-40">
          <img
            src="/images/arrow.svg"
            className="object-contain w-24 mb-5 lg:w-40 lg:absolute lg:mb-0 lg:top-24"
          />

          <h2 className="text-4xl font-bold md:text-5xl">
            Our Services <br />
            <span className="text-primary">Here's what we do:</span>
          </h2>
          <p className="px-2 mt-6 mb-10 text-lg font-light text-center">
            We offer a range of services to help you bring your product to life
            and <br className="hidden md:block" /> scale your company with
            technical excellence.
          </p>

          <div className="flex flex-wrap gap-5 px-3 py-5 md:grid md:grid-cols-2 xl:grid-cols-3 lg:px-40">
            <div className="w-full bg-white border-2 shadow-lg border-[#E8E1FB] rounded-3xl flex flex-col px-5 lg:px-10 items-start text-left xl:h-[570px] justify-end pb-14 lg:pb-10 py-14 lg:pt-0">
              <img src="/images/services-1.svg" className="object-contain" />
              <h4 className="mt-8 mb-5 text-2xl font-bold">
                Product fundamentals
              </h4>
              <p className="text-lg font-light text-black text-opacity-70">
                We conduct research to gain insights from usability testing,
                quantitative analysis, qualitative analysis and user interviews.
                This helps create a product with core elements such as product's
                value proposition, target market, user personas, and key
                features. This is the key to building products that people are
                happy to use.
              </p>
            </div>

            <div className="w-full bg-white border-2 shadow-lg border-[#E8E1FB] rounded-3xl flex flex-col px-5 lg:px-10 items-start text-left xl:h-[460px] justify-end pb-14 lg:pb-10 py-14 lg:pt-0">
              <img src="/images/services-2.svg" className="object-contain" />
              <h4 className="mt-8 mb-5 text-2xl font-bold">Product design</h4>
              <p className="text-lg font-light text-black text-opacity-70">
                We design products that are easy to use, attractive and
                functional. Maze Software's digital product design services
                encompass all of this along with a cutting-edge technology stack
                and superior custom development.
              </p>
            </div>

            <div className="w-full bg-white border-2 shadow-lg border-[#E8E1FB] rounded-3xl flex flex-col px-5 lg:px-10 items-start text-left xl:h-[570px] justify-end pb-14 lg:pb-10 py-14 lg:pt-0">
              <img src="/images/services-3.svg" className="object-contain" />
              <h4 className="mt-8 mb-5 text-2xl font-bold">
                Software development
              </h4>
              <p className="text-lg font-light text-black text-opacity-70">
                We are passionate about helping you unleash your ideas into
                digital products that are successfully used.
                <span className="mt-2">
                  We prototype, construct, deliver, and support all solutions,
                  from single POC and MVP to enterprise, using our experience in
                  UI/UX, business analysis, and development.
                </span>
              </p>
            </div>

            <div className="w-full bg-white border-2 shadow-lg border-[#E8E1FB] rounded-3xl flex flex-col px-5 lg:px-10 items-start text-left xl:h-[460px] justify-end pb-14 lg:pb-10 py-14 lg:pt-0">
              <img src="/images/services-4.svg" className="object-contain" />
              <h4 className="mt-8 mb-5 text-2xl font-bold">Maintenance</h4>
              <p className="text-lg font-light text-black text-opacity-70">
                We ensure that the product will continue to function flawlessly
                and efficiently after approval. This includes tasks such as
                troubleshooting and fixing errors, updating and updating
                software, and providing technical support to users.
              </p>
            </div>

            <div className="w-full bg-white border-2 shadow-lg border-[#E8E1FB] rounded-3xl flex flex-col px-5 lg:px-10 items-start text-left xl:h-[570px] justify-end pb-14 lg:pb-10 py-14 lg:pt-0 xl:-mt-[110px]">
              <img src="/images/services-5.svg" className="object-contain" />
              <h4 className="mt-8 mb-5 text-2xl font-bold">
                Product management
              </h4>
              <p className="text-lg font-light text-black text-opacity-70">
                At Maze Softwares, we analyze information such as customer
                surveys, competitive intelligence, industry analysis, trends,
                competitive activity and documentation requirements to define
                product strategy, improve product, manage product and create
                roadmaps that makes product viable.
              </p>
            </div>

            <div className="w-full bg-white border-2 shadow-lg border-[#E8E1FB] rounded-3xl flex flex-col px-5 lg:px-10 items-start text-left xl:h-[460px] justify-end pb-14 lg:pb-10 py-14 lg:pt-0">
              <img src="/images/services-6.svg" className="object-contain" />
              <h4 className="mt-8 mb-5 text-2xl font-bold">Launch & Growth</h4>
              <p className="text-lg font-light text-black text-opacity-70">
                We know how to get to the starting line on time and on budget,
                but that's not all. We strongly believe in delivering
                continuous, frictionless, agile product releases by making
                informed decisions based on measured insights from real users.
                Not too much?
              </p>
            </div>
          </div>
          <div className="mt-14">
            <a
              className="px-16 py-4 text-lg font-semibold text-black border border-black rounded-lg"
              href={publicRoutes.services}
            >
              Learn more about services
            </a>
          </div>
        </div>
      </section>

      <section className="relative pb-24 md:pb-40">
        <div className="absolute z-0 flex w-full h-full">
          <div className="bg-[#0D0032] w-1/2 rounded-tr-[60px]"></div>
          <div className="bg-[#0D0032] w-1/2 rounded-tl-[60px]"></div>
        </div>
        <div className="relative z-50 pt-28 md:pt-40">
          <div className="flex flex-col mb-16 md:mb-28">
            <h2 className="text-3xl font-bold text-white md:text-5xl">
              Built by Maze softwares
            </h2>
            <img
              src="/images/hr-yellow.svg"
              className="object-contain self-end w-36 md:w-auto md:self-center md:pl-[21%] mr-10 h-4 -mt-1"
            />
          </div>

          <div className="flex flex-col mx-5 md:mx-20 lg:mx-40 gap-y-20 md:gap-y-32">
            <div className="flex flex-col items-center justify-between lg:flex-row gap-x-10 gap-y-5 lg:gap-y-0">
              <div className="flex justify-start lg:w-4/6">
                <a target="_blank" href="https://bitoshi.africa">
                  <img
                    src="/images/bitoshi-app.svg"
                    className="object-contain"
                  />
                </a>
              </div>
              <div className="text-left text-white lg:w-2/6">
                <h5 className="text-[#ED5ABF] text-lg md:text-2xl font-semibold">
                  FINTECH
                </h5>
                <h4 className="mt-3 mb-5 text-3xl font-semibold md:text-5xl">
                  Bitoshi.africa <br className="hidden md:block" /> mobile app
                </h4>
                <p className="font-light leading-8 md:text-xl">
                  Overall, Bitoshi Africa is a promising new fintech platform
                  that is helping to bridge the gap between traditional finance
                  and the cryptocurrency world.
                </p>
              </div>
            </div>

            <div className="flex flex-col-reverse items-center justify-between lg:flex-row gap-x-10 gap-y-5 lg:gap-y-0">
              <div className="text-left text-white lg:w-2/6">
                <h5 className="text-[#ED5ABF] text-lg md:text-2xl font-semibold">
                  B2B SAAS
                </h5>
                <h4 className="mt-3 mb-5 text-3xl font-semibold md:text-5xl">
                  Pavelify Web App
                </h4>
                <p className="font-light leading-8 md:text-xl">
                  Pavelify is a cutting-edge B2B customer service and sales
                  platform that enables businesses to communicate with both
                  customers and prospects, convert leads into customers, and
                  increase revenue and workflow efficiency.
                </p>
              </div>
              <div className="flex justify-end lg:w-4/6">
                <a target="_blank" href="https://pavelify.com">
                  <img
                    src="/images/pavelify-app.svg"
                    className="object-contain"
                  />
                </a>
              </div>
            </div>

            <div className="flex flex-col items-center justify-between lg:flex-row gap-x-10 gap-y-5 lg:gap-y-0">
              <div className="flex justify-start lg:w-4/6">
                <a target="_blank" href="https://incorpuk.com">
                  <img
                    src="/images/incorpuk-app.svg"
                    className="object-contain"
                  />
                </a>
              </div>
              <div className="text-left text-white lg:w-2/6">
                <h5 className="text-[#ED5ABF] text-lg md:text-2xl font-semibold">
                  BUSINESS SERVICE
                </h5>
                <h4 className="mt-3 mb-5 text-3xl font-semibold md:text-5xl">
                  Incorpuk
                </h4>
                <p className="font-light leading-8 md:text-xl">
                  Incorpuk is a technology driven business infrastructure that
                  allows founders start and manage a UK company from anywhere in
                  the world, access UK and international business banking,
                  payment gateways (Stripe, PayPal, etc), startup resources and
                  stay compliant.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative pt-20 pb-40 lg:pt-28 bg-primary">
        <h2 className="hidden text-5xl font-bold text-white lg:block mb-28">
          Here is why you <br className="hidden lg:block" /> should use us
        </h2>
        <div className="flex flex-col items-start mx-5 text-left text-white lg:flex-row lg:mx-40 gap-x-16 gap-y-8 lg:gap-y-10">
          <div className="flex flex-col-reverse text-center lg:w-5/12 lg:flex-col lg:text-left">
            <p className="font-light lg:text-2xl">
              We are a result-driven team, who takes clients' satisfaction as
              our major priority. Our Clientele success is driven by 3 factors,
              which are highlighted below.
            </p>
            <h2 className="my-5 text-3xl font-bold text-white lg:hidden md:text-4xl">
              Here is why you <br className="hidden lg:block" /> should use us
            </h2>
            <div className="self-center lg:mt-20 lg:self-start">
              <img
                src="/images/notes.svg"
                className="object-contain w-48 lg:w-[320px]"
              />
            </div>
          </div>
          <div className="flex flex-col lg:w-7/12">
            <div className="mb-10">
              <div className="bg-white h-[1px] md:h-[2px]"></div>
              <h5 className="mt-5 ml-5 text-lg font-light md:mt-10 md:ml-0 md:text-2xl">
                01
              </h5>
              <div className="mt-2 ml-5 md:ml-20 md:mt-5">
                <h4 className="mb-2 text-xl font-semibold md:text-3xl md:mb-4">
                  Consistent Delivery
                </h4>
                <p className="font-thin md:text-xl">
                  We have developed the capacity to consistently fulfill our
                  commitments to customers regarding the delivery of products or
                  services. On-time delivery and precise order fulfillment are
                  all examples of this. We are prepared to keep delivering on
                  our promises to customers because we hope that doing so helps
                  to build trust and loyalty and can help us maintain a
                  competitive edge.
                </p>
              </div>
            </div>
            <div className="mb-10">
              <div className="bg-white h-[1px] md:h-[2px]"></div>
              <h5 className="mt-5 ml-5 text-lg font-light md:mt-10 md:ml-0 md:text-2xl">
                02
              </h5>
              <div className="mt-2 ml-5 md:ml-20 md:mt-5">
                <h4 className="mb-2 text-xl font-semibold md:text-3xl md:mb-4">
                  Speed
                </h4>
                <p className="font-thin md:text-xl">
                  We implement working software in production with a single
                  click, delivering functionality rapidly and continuously.
                  Aside this we have a great customer service who take our
                  clients product as priorities and attend to them swiftly.
                </p>
              </div>
            </div>

            <div className="mb-10">
              <div className="bg-white h-[1px] md:h-[2px]"></div>
              <h5 className="mt-5 ml-5 text-lg font-light md:mt-10 md:ml-0 md:text-2xl">
                03
              </h5>
              <div className="mt-2 ml-5 md:ml-20 md:mt-5">
                <h4 className="mb-2 text-xl font-semibold md:text-3xl md:mb-4">
                  Result-Oriented Team
                </h4>
                <p className="font-thin md:text-xl">
                  Our team is focused on achieving specific goals or objectives
                  and is result-oriented. A commitment to achieving measurable
                  outcomes, a shared vision, and a clear sense of purpose define
                  us. Due to our high level of motivation, and high level of
                  accountability for our actions.
                </p>
              </div>
              <div className="bg-white h-[1px] md:h-[2px] mt-10"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative py-20 pb-40 bg-transparent md:py-28">
        <div className="flex flex-col mb-10 md:mb-20">
          <h2 className="text-3xl font-bold text-black md:text-5xl">
            Why choose <br className="md:hidden" /> Maze softwares?
          </h2>
          <img
            src="/images/hr-orange.svg"
            className="object-contain self-end md:self-center md:pl-[28%] w-36 mr-24 md:mr-10 h-4 -mt-1"
          />
        </div>

        <div className="grid items-center justify-center grid-rows-3 lg:grid-cols-3 lg:grid-rows-1 lg:mx-40 gap-y-5 lg:gap-y-0 lg:gap-x-10">
          <div className="bg-white drop-shadow-xl shadow-[#eee] rounded-3xl flex flex-col items-center justify-center p-10 w-[360px] h-[160px] md:h-[200px]">
            <h4 className="mb-2 text-5xl font-bold md:text-7xl text-primary">
              9x
            </h4>
            <p className="text-xl text-black text-opacity-60 md:text-2xl">
              faster
            </p>
          </div>
          <div className="bg-white drop-shadow-xl shadow-[#eee] rounded-3xl flex flex-col items-center justify-center p-10 w-[360px] h-[160px] md:h-[200px]">
            <h4 className="mb-2 text-5xl font-bold md:text-7xl text-primary">
              10+
            </h4>
            <p className="text-xl text-black text-opacity-60 md:text-2xl">
              years of experience
            </p>
          </div>
          <div className="bg-primary drop-shadow-xl shadow-[#eee] rounded-3xl flex flex-col items-center justify-center p-10 w-[360px] h-[160px] md:h-[200px]">
            <h4 className="mb-2 text-5xl font-bold text-white md:text-7xl">
              99%
            </h4>
            <p className="text-xl text-white text-opacity-60 md:text-2xl">
              happy client
            </p>
          </div>
        </div>
      </section>
      <section className="relative flex flex-col justify-center w-full pb-40 md:mt-20">
        <div>
          <span className="font-semibold md:text-xl bg-[#F4F2FA] rounded-full py-3 px-14">
            Having doubts?
          </span>
        </div>
        <div className="flex items-start justify-center gap-x-4">
          <h2 className="ml-3 text-4xl font-bold text-center text-black md:text-6xl mt-7 md:ml-0">
            What our clients <br /> have to say
          </h2>
          <img
            src="/images/heart.svg"
            className="object-contain w-14 md:w-auto"
          />
        </div>

        <div className="mt-20 overflow-x-hidden">
          <Carousel
            options={{
              autoplay: 5000,
              breakpoints: {
                1100: {
                  perView: 2,
                },
                600: {
                  perView: 1,
                },
              },
            }}
          >
            <Slide>
              <div className="">
                <div className="rounded-xl border border-black border-opacity-70 px-5 py-16 flex flex-col items-start text-left h-[400px]">
                  <img src="/images/maze.svg" className="object-contain w-16" />
                  <h4 className="mt-5 mb-3 text-2xl font-semibold">
                    Bitoshi.africa
                  </h4>
                  <p className="text-lg font-light">
                    My product's development reached its pinnacle in large part
                    thanks to Maze Softwares. They are a valuable partner
                    because of their honesty and technological expertise.
                  </p>
                </div>
              </div>
            </Slide>
            <Slide>
              <div className="">
                <div className="rounded-xl border border-black border-opacity-70 px-5 py-16 flex flex-col items-start text-left h-[400px]">
                  <img
                    src="/images/pavelify-logo.svg"
                    className="object-contain w-16"
                  />
                  <h4 className="mt-5 mb-3 text-2xl font-semibold">Pavelify</h4>
                  <p className="text-lg font-light">
                    It is the knowledge, timelessness and thoroughness of Maze
                    Softwares that has set them apart from the competition.
                  </p>
                </div>
              </div>
            </Slide>
            <Slide>
              <div className="">
                <div className="rounded-xl border border-black border-opacity-70 px-5 py-16 flex flex-col items-start text-left h-[400px]">
                  <img
                    src="/images/incorpuk-logo.svg"
                    className="object-contain w-16"
                  />
                  <h4 className="mt-5 mb-3 text-2xl font-semibold">Incorpuk</h4>
                  <p className="text-lg font-light">
                    The quality of Maze softwares was great, and I never had any
                    concerns about their ability to meet expectations.
                  </p>
                </div>
              </div>
            </Slide>
          </Carousel>
        </div>
      </section>

      <section className="relative bg-transparent md:pb-40">
        <div className="flex flex-col lg:mb-20">
          <div id="faq" className="flex items-center justify-center">
            <h2 className="text-3xl font-bold text-black md:text-5xl">
              Frequently asked <br className="md:hidden" /> questions
            </h2>
            <img
              src="/images/question.svg"
              className="object-contain -mt-5 -ml-4 w-14 md:w-24 md:-ml-10 md:-mt-0 md:-mb-8"
            />
          </div>
          <img
            src="/images/hr-blue.svg"
            className="object-contain self-center w-32 md:w-auto md:pl-[24%] mr-8 md:mr-10 h-4 -mt-2 md:-mt-10"
          />
        </div>

        <div className="flex flex-col justify-center mt-10 lg:mx-40 lg:mt-32">
          {faqs.map((faq) => (
            <div
              key={faq.title}
              onClick={() => toggleFaq(faq)}
              className={`border-b-black border-opacity-5 border-b-4 flex flex-col md:mx-20 lg:mx-40 pl-5 md:pl-10 lg:pl-20 cursor-pointer text-left ${
                seletedFaq?.title === faq.title
                  ? " text-white bg-primary rounded-3xl px-20 py-16 mb-10"
                  : "py-10"
              }`}
            >
              <div className="flex items-center">
                <img
                  src="/icons/chevron.svg"
                  className="object-contain w-8 mr-5"
                />
                <h5 className="text-xl font-semibold md:text-2xl">
                  {faq.title}
                </h5>
              </div>
              <p
                className={`${
                  seletedFaq?.title === faq.title
                    ? "block font-light md:text-xl leading-8 mt-5 md:mt-8"
                    : "hidden"
                }`}
              >
                {faq.content}
              </p>
            </div>
          ))}
        </div>
      </section>
      <section className="relative py-20 pb-40 bg-white">
        <div>
          <span className="font-semibold md:text-xl bg-[#F4F2FA] rounded-full py-3 px-14">
            Get started
          </span>
        </div>
        <h2 className="text-4xl font-extrabold text-black md:text-6xl lg:text-7xl mt-7 mb-14">
          Build your products <br />
          <span className="text-primary">faster than ever</span>
        </h2>

        <div>
          <a
            className="px-10 py-4 text-xl font-light text-white rounded-lg bg-primary lg:py-5 lg:px-14 lg:text-2xl"
            href={publicRoutes.contact}
          >
            Get in touch
          </a>
        </div>

        <div className="relative flex items-end justify-center mt-20">
          <img
            src="/images/devices.svg"
            className="z-10 hidden object-contain -mb-3 md:inline-block md:w-32 lg:w-60 md:-mr-8 lg:-mr-14"
          />
          <div className="bg-gradient-to-br from-[#4d24c3] to-[#7150cf] rounded-2xl text-white md:w-[85%] lg:w-[60%] py-20 border-[5px] border-black border-opacity-20">
            <h3 className="text-4xl font-bold">
              <span className="text-[#f5d3e8]">Ready to</span> <br />
              <span>get started?</span>
            </h3>
            <div className="mt-5 text-sm font-light leading-6">
              <p>Tell us about your project and we will:</p>
              <ul className="list-disc list-inside">
                <li>Reply within 3 business hours.</li>
                <li>
                  Discuss your project on a quick call (We will sign an NDA if
                  requested)
                </li>
                <li>You get a quote</li>
              </ul>
            </div>

            <form
              method="post"
              ref={form}
              onSubmit={sendEmail}
              className="mx-5 mt-20 md:mx-20"
            >
              <div className="grid md:grid-cols-2 gap-x-5 gap-y-5">
                <input
                  required
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
                />
                <input
                  required
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
                />
                <input
                  type="text"
                  required
                  name="phoneNumber"
                  placeholder="Phone Number"
                  className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
                />
                <input
                  type="text"
                  required
                  name="email"
                  placeholder="Email Address"
                  className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
                />
                <input
                  type="text"
                  required
                  name="company"
                  placeholder="Company"
                  className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
                />
                <input
                  type="text"
                  required
                  name="industry"
                  placeholder="Industry"
                  className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
                />
                <input
                  type="text"
                  required
                  name="budget"
                  placeholder="Budget Size"
                  className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
                />
                <input
                  type="text"
                  name="hearFrom"
                  placeholder="Where did you here about us? (optional)"
                  className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
                />
                <textarea
                  required
                  name="aboutProject"
                  placeholder="Tell us about your project"
                  className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
                  rows={6}
                />
                <textarea
                  name="anythingElse"
                  placeholder="Anything else?"
                  className="px-5 py-3.5 text-white placeholder:text-[#ddd] rounded bg-black bg-opacity-10 border border-[#555] box-shadow outline-none"
                  rows={6}
                />
              </div>

              <div className="flex items-center justify-center mb-8 mt-14">
                <button className="px-16 py-4 text-lg font-semibold text-black bg-white rounded-lg">
                  Submit
                </button>
              </div>
            </form>
            <p className="text-white">
              or contact us via email-{" "}
              <a href="mailto:support@mazesoftwares.com">
                support@mazesoftwares.com
              </a>
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </Container>
  );
};

export default Home;
